import { graphql, useStaticQuery } from 'gatsby';
import { ArtItem } from '../types';

export type QueryResponse = {
    contentfulAbout: {
        notFoundImage: {
            id: string;
            title: string;
            cw: string;
            media: {
                title: string;
                image: {
                    src: string;
                }
            };
            received: string;
            artist: {
                id: string;
                name: string;
                url: string;
            };
        };
    };
};

export const useNotFoundImageQuery = (): ArtItem => {
    const { contentfulAbout } = useStaticQuery<QueryResponse>(graphql`
    query NotFoundImageQuery {
      contentfulAbout {
        notFoundImage {
          id
          title
          cw
          media {
            title
            image: resize(width: 400, quality: 100) {
              src
            }
          }
          received(formatString: "YYYY-MM-DD")
          artist {
            id
            name
            url
          }
        }
      }
    }
  `);

    return {
        title: contentfulAbout.notFoundImage.title,
        cw: contentfulAbout.notFoundImage.cw,
        received: contentfulAbout.notFoundImage.received,
        artist: contentfulAbout.notFoundImage.artist,
        media: {
            alt: contentfulAbout.notFoundImage.media.title,
            src: contentfulAbout.notFoundImage.media.image.src,
        },
    }
}
