import React from 'react';
import styled from 'styled-components';
import { Heading, Box, Link, Flex, Image } from 'rebass/styled-components';
import { Fade } from 'react-awesome-reveal';
import Layout from 'gatsby-theme-mate/src/components/Layout';
import Section from 'gatsby-theme-mate/src/components/Section';
import Triangle from 'gatsby-theme-mate/src/components/Triangle';
import { useNotFoundImageQuery } from '../queries/useNotFoundImageQuery';

const NotFoundPage = () => {
  const artwork = useNotFoundImageQuery();

  return (
    <Layout>
      <Section.Container Background={Background}>
        <Box width={[320, 400, 600]} m="auto">
          <Heading color="text" fontSize={['8rem', '12rem', '14rem']} as="h1">
            404
        </Heading>
          <Heading color="secondary" fontSize={['4rem', '5rem', '6rem']} as="h2">
            There isn&apos;t anything here
        </Heading>
        </Box>
        <FooterText href={artwork.artist.url}>
          Art by {artwork.artist.name}
        </FooterText>
        <FooterImage src={artwork.media.src} alt={artwork.media.alt} />
      </Section.Container>
    </Layout>
  )
}

const Background = () => (
  <>
    <Triangle
      color="muted"
      height={['35vh', '80vh']}
      width={['95vw', '60vw']}
    />

    <Triangle
      color="primary"
      height={['25vh', '35vh']}
      width={['75vw', '60vw']}
      position="bottom-right"
    />

    <Triangle
      color="secondary"
      height={['10vh', '20vh']}
      width={['50vw', '50vw']}
      position="top-right"
    />
  </>
);

const FooterImage = styled(Image)`
    width: 400px;
    position: absolute;
    right: 0px;
    bottom: 0px;
`;

const FooterText = styled(Link)`
    position: absolute;
    left: 1em;
    bottom: 1em;
    color: white;
    text-decoration: none;
    font-size: 1em;

    &:hover {
        text-decoration: underline;
    }
`;

export default NotFoundPage;